import Axios, { AxiosRequestConfig } from 'axios';
import ErrorException from '../exception/ErrorException';
import WarningException from '../exception/WarningException';
import i18next from 'i18next';

import { languageDefault } from "../../utils/Language";

type TLogin = {
  idToken       : string,
  refreshToken  : string,
  userLanguage ?: string,
  expiresIn     : number
};

export default class Auth {

  private backendHost = process.env.REACT_APP_API_ENDPOINT;
  private prefix      = '/admin/auth';

  private requestConfig : Partial<AxiosRequestConfig> = {};

  constructor() {
    this.requestConfig.baseURL = `${this.backendHost}${this.prefix}`;
  }

  async login(email: string, password: string) : Promise<TLogin> {
    try {
      var result = await Axios.post(`/login`, { email, password }, this.requestConfig);
    } catch (err) {
      console.log(err);
      if (!err.response) {
        throw new ErrorException(i18next.t('serverError-connecting'));
      }

      switch (err.response.status) {
        case 401:
        case 403:
          throw new WarningException(i18next.t('serverError-invalidCredential'));
        default:
          throw new ErrorException(i18next.t('serverError-unknown'));
      }
    }
    const { idToken, refreshToken, expiresIn } = result.data;
    const userLanguage = result.data.userLanguage || languageDefault.acronym;
    
    return {
      idToken,
      refreshToken,
      expiresIn,
      userLanguage
    };
  }

  async refreshToken(refreshTokenString: string) : Promise<TLogin> {
    try {
      var result = await Axios.post(`/token`, { refreshToken: refreshTokenString }, this.requestConfig);
    } catch (err) {
      console.log(err);
      if (!err.response) {
        throw new ErrorException(i18next.t('serverError-connecting'));
      }

      switch (err.response.status) {
        case 401:
        case 403:
          throw new WarningException(i18next.t('serverError-invalidCredential'));
        default:
          throw new ErrorException(i18next.t('serverError-unknown'));
      }
    }

    const { idToken, refreshToken, expiresIn } = result.data;
    
    return {
      idToken,
      refreshToken,
      expiresIn,
    };
  }

}