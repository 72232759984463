export interface ISidebarMenuItem {
    heading?: string;
    name?: string;
    icon?: string;
    translate?: string;
    path?: string;
    label?: {
        value: string | number;
        color: string;
    };
    submenu?: ISidebarMenu;
}

export interface ISidebarMenu extends Array<ISidebarMenuItem> {}

const Menu: ISidebarMenu = [
    {
        heading: 'Welcome',
        translate: 'sidebar.heading.HEADER'
    },
    {
        name: 'Home',
        icon: 'icon-home',
        translate: 'sidebar.nav.HOME',
        path: '/home',
    },
    {
        name: 'Users',
        icon: 'icon-user',
        translate: 'sidebar.nav.USERS',
        submenu: [
            {
                name: 'List users',
                path: '/user/all',
                translate: 'sidebar.nav.LIST_USERS',
            },
            {
                name: 'New user',
                path: '/user/new',
                translate: 'sidebar.nav.NEW_USER',
            },
            {
                name: 'List users SAP',
                path: '/user/gsis/all',
                translate: 'sidebar.nav.LIST_USERS_GSIS',
            },
        ]
    },
    {
        name: 'Products',
        icon: 'icon-folder',
        translate: 'sidebar.nav.PRODUCTS',
        submenu: [
            {
                name: 'List products',
                path: '/product/all',
                translate: 'sidebar.nav.LIST_PRODUCTS',
            },
            {
                name: 'New product',
                path: '/product/new',
                translate: 'sidebar.nav.NEW_PRODUCT',
            },
        ]
    },
    {
        name: 'Pieces',
        icon: 'icon-grid',
        translate: 'sidebar.nav.PIECES',
        submenu: [
            {
                name: 'List pieces',
                path: '/piece/all',
                translate: 'sidebar.nav.LIST_PIECES',
            },
            {
                name: 'New piece',
                path: '/piece/new',
                translate: 'sidebar.nav.NEW_PIECE',
            },
        ]
    },
    {
        name: 'Errors',
        icon: 'icon-exclamation',
        translate: 'sidebar.nav.ERRORS',
        submenu: [
            {
                name: 'Errors code',
                path: '/listErrorCode/all',
                translate: 'sidebar.nav.ERROR_CODE',
            },
            {
                name: 'Errors without code',
                path: '/listErrorWithoutCode/all',
                translate: 'sidebar.nav.ERROR_WITHOUT_CODE',
            },
            {
                name: 'Troubleshooting',
                path: '/troubleshooting/all',
                translate: 'sidebar.nav.TROUBLESHOOTING',
            }
        ]
    },
    {
        name: 'Orders',
        icon: 'icon-basket',
        translate: 'sidebar.nav.ORDERS',
        path: '/order/all',
    },
    {
        name: 'DocumentTypes',
        icon: 'icon-list',
        translate: 'sidebar.nav.DOCUMENT_TYPE',
        submenu: [
            {
                name: 'List document types',
                path: '/documentType/all',
                translate: 'sidebar.nav.LIST_DOCUMENT_TYPE',
            },
            {
                name: 'New document type',
                path: '/documentType/new',
                translate: 'sidebar.nav.NEW_DOCUMENT_TYPE',
            },
        ]
    },
    {
        name: 'Categories',
        icon: 'icon-equalizer',
        translate: 'sidebar.nav.CATEGORIES',
        path: '/category/all'
    },
    {
        name: 'Documents',
        icon: 'icon-docs',
        translate: 'sidebar.nav.DOCUMENTS',
        path: '/document/all',
    },
    {
        name: 'Import',
        icon: 'icon-cloud-upload',
        translate: 'sidebar.nav.IMPORT',
        submenu: [
            {
                name: 'Import products',
                path: '/import/products',
                translate: 'sidebar.nav.IMPORT_PRODUCTS',
            },
            {
                name: 'Import pieces',
                path: '/import/pieces',
                translate: 'sidebar.nav.IMPORT_PIECES',
            },
            {
                name: 'Import SAP users',
                path: '/import/gsis-users',
                translate: 'sidebar.nav.IMPORT_GSIS_USERS',
            },
        ]
    },
    {
        name: 'Extra',
        icon: 'icon-layers',
        translate: 'sidebar.nav.ADDITIONAL',
        submenu: [
            {
                name: 'Error fields',
                path: '/additional/errorField',
                translate: 'sidebar.nav.ERROR_FIELDS',
            },
        ]
    }
];

export default Menu;
