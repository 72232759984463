import Axios, { AxiosRequestConfig } from 'axios';
import ErrorException from '../exception/ErrorException';
import WarningException from '../exception/WarningException';
import i18next from 'i18next';

type TLanguage = {
  createdAt    : string,
  updatedAt    : string,
  deletedAt    : string,
  id           : string,
  acronym      : string,
  translations : {
    text_i18n  : {}
  }
};

export default class Language {

  private backendHost = process.env.REACT_APP_API_ENDPOINT;
  private prefix      = '/language';

  private requestConfig : Partial<AxiosRequestConfig> = {};

  constructor() {
    this.requestConfig.baseURL = `${this.backendHost}${this.prefix}`;
  }

  async getLanguage() : Promise<TLanguage> {
    try {
      var result = await Axios.get(`/`, this.requestConfig);
    } catch (err) {
      console.log(err);
      if (!err.response) {
        throw new ErrorException(i18next.t('serverError-connecting'));
      }

      switch (err.response.status) {
        case 401:
        case 403:
          throw new WarningException(i18next.t('serverError-invalidCredential'));
        default:
          throw new ErrorException(i18next.t('serverError-unknown'));
      }
    }
    
    return result.data;

  }

}