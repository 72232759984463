import React from 'react';
import { NotificationContainer } from '../Elements/Notification';

const BaseEmpty: React.FC = ({ children }) => (
    <div className="wrapper">
        <NotificationContainer />
        <section className="section-container-center">
            {children}
        </section>
    </div>
);

export default BaseEmpty;
