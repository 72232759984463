import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';

// App Routes
import Routes from './Routes';

// Vendor dependencies
import "./Vendor";
// Application Styles
import './styles/bootstrap.scss';
import './styles/app.scss'

import StorageLanguage from './services/storage/Language';
import { languagesList, languageDefault } from "./utils/Language";
import HttpLanguage from './services/http/Language';
import { WithTranslation, withTranslation } from 'react-i18next';

// declare var PUBLIC_URL: string;

class App extends Component<WithTranslation> {
  
  async componentDidMount(){
    let responseTranslationBackend;
    
    if (!StorageLanguage.getItem('language')) {
      let userLang = languageDefault.acronym;
      for (let i = 0; i < languagesList.length; i++) {
        if (navigator.language.slice(0, 2) == languagesList[i].acronym){
          userLang = languagesList[i].acronym;
          break;
        }
      }
      StorageLanguage.saveLanguage(userLang);
      this.props.i18n.changeLanguage(userLang, () => {
        window.location.reload();
      });
    }

    responseTranslationBackend = await new HttpLanguage().getLanguage();
    StorageLanguage.saveLanguageBackend(JSON.stringify(responseTranslationBackend));
  }

  render() {

    // specify base href from env varible 'PUBLIC_URL'
    // use only if application isn't served from the root
    // for development it is forced to root only
    const basename = '/'; // process.env.NODE_ENV === 'development' ? '/' : (PUBLIC_URL || '/');

    return (
      <BrowserRouter basename={basename}>
        <Routes />
      </BrowserRouter>
    );

  }
}

export default withTranslation()(App);
