import React, { Suspense, lazy } from 'react';
import { withRouter, Switch, Route, Redirect, RouteProps } from 'react-router-dom';

/* loader component for Suspense*/
import PageLoader from './components/Common/PageLoader';

import BaseEmpty from './components/Layout/BaseEmpty';
import Base from './components/Layout/Base';

import StorageAuth from './services/storage/Auth';
import AuthInterceptor from './services/AuthInterceptor';
import RoutePathLibrary from './utils/RoutePath';
AuthInterceptor();

/* Used to render a lazy component with react-router */
const waitFor = (Tag: React.LazyExoticComponent<any>) => (props: any) => <Tag {...props}/>;

const LoginForm  = lazy(() => import('./components/LoginForm/LoginForm'));
const Home       = lazy(() => import('./components/Home/Home'));

const UserNew = lazy(() => import('./components/User/NewUser'));
const UserDetail = lazy(() => import('./components/User/DetailUser'));
const UserAll = lazy(() => import('./components/User/ListUsers'));
const UserGSISDetail = lazy(() => import('./components/User/DetailUserGSIS'));
const UserGSISAll = lazy(() => import('./components/User/ListUsersGSIS'));

const ProductNew = lazy(() => import('./components/Product/NewProduct'));
const ProductDetail = lazy(() => import('./components/Product/DetailProduct'));
const ProductAll = lazy(() => import('./components/Product/ListProducts'));

const PieceNew = lazy(() => import('./components/Piece/NewPiece'));
const PieceAll = lazy(() => import('./components/Piece/ListPieces'));
const PieceDetail = lazy(() => import('./components/Piece/DetailPiece'));

const ListErrorCodeNew = lazy(() => import('./components/ListErrorCode/NewListErrorCode'));
const ListErrorCodeAll = lazy(() => import('./components/ListErrorCode/ListListErrorCode'));
const ListErrorCodeDetail = lazy(() => import('./components/ListErrorCode/DetailListErrorCode'));

const ListErrorWithoutCodeNew = lazy(() => import('./components/ListErrorCodeNo/NewListErrorCodeNo'));
const ListErrorWithoutCodeAll = lazy(() => import('./components/ListErrorCodeNo/ListListErrorCodeNo'));
const ListErrorWithoutCodeDetail = lazy(() => import('./components/ListErrorCodeNo/DetailListErrorCodeNo'));

const TroubleshootingNew = lazy(() => import('./components/Troubleshooting/NewTroubleshooting'));
const TroubleshootingAll = lazy(() => import('./components/Troubleshooting/ListTroubleshooting'));
const TroubleshootingDetail = lazy(() => import('./components/Troubleshooting/DetailTroubleshooting'));

const OrderAll = lazy(() => import('./components/Order/ListOrder'));
const OrderDetail = lazy(() => import('./components/Order/DetailOrder'));

const DocumentTypeNew = lazy(() => import('./components/DocumentType/NewDocumentType'));
const DocumentTypeAll = lazy(() => import('./components/DocumentType/ListDocumentType'));
const DocumentTypeDetail = lazy(() => import('./components/DocumentType/DetailDocumentType'));

const DocumentAll = lazy(() => import('./components/Document/ListDocument'));

const ImportProducts = lazy(() => import('./components/Import/Products/ImportProducts'));
const ImportPieces = lazy(() => import('./components/Import/Pieces/ImportPieces'));
const ImportGsisUsers = lazy(() => import('./components/Import/GSISUsers/ImportGSISUsers'));

const ErrorFields = lazy(() => import('./components/ErrorField/ListErrorField'));

const Categories = lazy(() => import('./components/Category/ListCategory'));

const Routes = ({ location }: RouteProps ) => {
    const isLoggedIn = StorageAuth.isTokenAlive(StorageAuth.getSession());
    if (!isLoggedIn) {
        return (
            // Page Layout component wrapper
            <BaseEmpty>
                <Suspense fallback={<PageLoader/>}>
                    <Switch location={location}>
                        <Route path={ RoutePathLibrary.login } component={waitFor(LoginForm)}/>
                        <Redirect to={ RoutePathLibrary.login } />
                    </Switch>
                </Suspense>
            </BaseEmpty>
        );
    }

    return (
        // Layout component wrapper
        <Base>
            <div>
                <Suspense fallback={<div></div>}>
                    <Switch location={location}>
                        <Route path={ RoutePathLibrary.home } component={waitFor(Home)}/>
                        <Route path="/user/new" component={waitFor(UserNew)}/>
                        <Route path="/user/gsis/all" component={waitFor(UserGSISAll)}/>
                        <Route path="/user/gsis/:id" component={waitFor(UserGSISDetail)}/>
                        <Route path="/user/all" component={waitFor(UserAll)}/>
                        <Route path="/user/:id" component={waitFor(UserDetail)}/>
                        <Route path="/product/new" component={waitFor(ProductNew)}/>
                        <Route path="/product/all" component={waitFor(ProductAll)}/>
                        <Route path="/product/:id" component={waitFor(ProductDetail)}/>
                        <Route path="/piece/new" component={waitFor(PieceNew)}/>
                        <Route path="/piece/all" component={waitFor(PieceAll)}/>
                        <Route path="/piece/:id" component={waitFor(PieceDetail)}/>
                        <Route path="/listErrorCode/new" component={waitFor(ListErrorCodeNew)}/>
                        <Route path="/listErrorCode/all" component={waitFor(ListErrorCodeAll)}/>
                        <Route path="/listErrorCode/:id" component={waitFor(ListErrorCodeDetail)}/>
                        <Route path="/listErrorWithoutCode/new" component={waitFor(ListErrorWithoutCodeNew)}/>
                        <Route path="/listErrorWithoutCode/all" component={waitFor(ListErrorWithoutCodeAll)}/>
                        <Route path="/listErrorWithoutCode/:id" component={waitFor(ListErrorWithoutCodeDetail)}/>
                        <Route path="/troubleshooting/new" component={waitFor(TroubleshootingNew)}/>
                        <Route path="/troubleshooting/all" component={waitFor(TroubleshootingAll)}/>
                        <Route path="/troubleshooting/:id" component={waitFor(TroubleshootingDetail)}/>
                        <Route path="/order/all" component={waitFor(OrderAll)}/>
                        <Route path="/order/:id" component={waitFor(OrderDetail)}/>
                        <Route path="/documentType/new" component={waitFor(DocumentTypeNew)}/>
                        <Route path="/documentType/all" component={waitFor(DocumentTypeAll)}/>
                        <Route path="/documentType/:id" component={waitFor(DocumentTypeDetail)}/>
                        <Route path="/document/all" component={waitFor(DocumentAll)}/>
                        <Route path="/import/products" component={waitFor(ImportProducts)}/>
                        <Route path="/import/pieces" component={waitFor(ImportPieces)}/>
                        <Route path="/import/gsis-users" component={waitFor(ImportGsisUsers)}/>
                        <Route path="/additional/errorField" component={waitFor(ErrorFields)}/>
                        <Route path="/category/all" component={waitFor(Categories)}/>

                        <Redirect to={ RoutePathLibrary.home }/>
                    </Switch>
                </Suspense>
            </div>
        </Base>
    )
}

export default withRouter(Routes);
