import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, ListGroup, ListGroupItem } from 'reactstrap';

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { toggleSetting } from '../../store/actions/actions';
import { Auth } from '../../services/storage/Auth';
import { Language } from '../../services/storage/Language';
import RoutePathLibrary from '../../utils/RoutePath';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import HttpStorageI18n from "../../services/storage/Language";

interface HeaderProps extends WithTranslation {
    toggleSetting: typeof toggleSetting
};

class Header extends Component<HeaderProps> {

    state = {
      navSearchOpen : false,
      currentLang   : new Language().getCurrentLanguage(),
    };

    constructor(props:any) {
      super(props);
      this.state.navSearchOpen = false;
    }

    changeLanguage = (lng: string) => {
      HttpStorageI18n.saveLanguage(lng);
      this.props.i18n.changeLanguage(lng);
      this.state.currentLang = lng;
    }

    toggleOffsidebar = (e: React.MouseEvent) => {
        e.preventDefault()
        this.props.toggleSetting('offsidebarOpen');
    }

    toggleCollapsed = (e: React.MouseEvent) => {
        e.preventDefault()
        this.props.toggleSetting('isCollapsed');
        this.resize()
    }

    toggleAside = (e: React.MouseEvent) => {
        e.preventDefault()
        this.props.toggleSetting('asideToggled');
    }

    resize () {
        const event = document.createEvent('HTMLEvents');
        event.initEvent('resize', true, false);
        document.dispatchEvent(event);
    }

    private logOut = () => {
        new Auth().removeSession();
        new Language().removeLanguageBackend();
        window.location.href =  RoutePathLibrary.login;
        
        return;
    }

    render = () => {
      return (
        <div id="header">
          <header className="topnavbar-wrapper">
              { /* START Top Navbar */ }
              <nav className="navbar topnavbar">
                  { /* START navbar header */ }
                  <div className="navbar-header">
                    <a className="navbar-brand" href="#/">
                      <div className="brand-logo">
                        <img className="img-fluid" style={{maxWidth: '80px'}} src="../../haier-logo-white.png" />
                      </div>
                      <div className="brand-logo-collapsed">
                        <img className="img-fluid" style={{maxWidth: '40px'}} src="../../haier-logo-white.png" />
                      </div>
                    </a>
                  </div>
                  { /* END navbar header */ }

                  { /* START Left navbar */ }
                  <ul className="navbar-nav mr-auto flex-row">
                      <li className="nav-item">
                          { /* Button used to collapse the left sidebar. Only visible on tablet and desktops */ }
                          <a href="" className="nav-link d-none d-md-block d-lg-block d-xl-block" onClick={ this.toggleCollapsed }>
                              <em className="fas fa-bars"></em>
                          </a>
                          { /* Button to show/hide the sidebar on mobile. Visible on mobile only. */ }
                          <a href=""  className="nav-link sidebar-toggle d-md-none" onClick={ this.toggleAside }>
                              <em className="fas fa-bars"></em>
                          </a>
                      </li>
                  </ul>
                  { /* END Left navbar */ }
                  { /* START Right Navbar */ }
                  <ul className="navbar-nav flex-row" style={{paddingTop: '.4rem'}}>
                    <li className="nav-item">
                      <UncontrolledDropdown>
                        <DropdownToggle tag="a" className="nav-link planet" caret>
                          <span>{ this.state.currentLang === 'en' ? 'English' : 'Español' }</span>
                        </DropdownToggle>
                        <DropdownMenu right className="dropdown-menu-right">
                          <DropdownItem onClick={() => this.changeLanguage('es')}>Español</DropdownItem>
                          <DropdownItem onClick={() => this.changeLanguage('en')}>English</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown >
                    </li>
                    <li className="nav-item">
                      <UncontrolledDropdown>
                        <DropdownToggle tag="a" className="nav-link user" caret>
                          <em className="icon-user"></em>
                        </DropdownToggle>
                        <DropdownMenu right className="dropdown-menu-right">
                          <DropdownItem onClick={this.logOut}><Trans i18nKey='header.logout'></Trans></DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown >
                    </li>
                  </ul>
                  { /* END Right Navbar */ }
              </nav>
              { /* END Top Navbar */ }
          </header>
        </div>
      );
    }
}


const mapDispatchToProps = (dispatch: Dispatch) => ({ toggleSetting: bindActionCreators(toggleSetting, dispatch) })

export default connect(null, mapDispatchToProps)(withTranslation()(Header));