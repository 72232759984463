import React from 'react';
import './PageLoader.scss'

const PageLoader : React.FC = () => (
    <div className="page-loader">
        <svg className="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle className="pathLoader" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
    </div>
)

export default PageLoader;