export default {
  login : '/login',
  home  : '/home',
  user  : {
    new        : '/user/new',
    list       : '/user/all',
    detail     : '/user',
    gsisList   : '/user/gsis/all',
    gsisDetail : '/user/gsis',
  },
  product: {
    new    : '/product/new',
    list   : '/product/all',
    detail : '/product'
  },
  piece: {
    new    : '/piece/new',
    list   : '/piece/all',
    detail : '/piece'
  },
  errorCode: {
    new    : '/errorCode/new',
    list   : '/errorCode/all',
    detail : '/errorCode'
  },
  errorWithoutCode: {
    new    : '/errorWithoutCode/new',
    list   : '/errorWithoutCode/all',
    detail : '/errorWithoutCode'
  },
  listErrorCode: {
    new    : '/listErrorCode/new',
    list   : '/listErrorCode/all',
    detail : '/listErrorCode'
  },
  listErrorWithoutCode: {
    new    : '/listErrorWithoutCode/new',
    list   : '/listErrorWithoutCode/all',
    detail : '/listErrorWithoutCode'
  },
  troubleshooting: {
    new    : '/troubleshooting/new',
    list   : '/troubleshooting/all',
    detail : '/troubleshooting'
  },
  order: {
    list   : '/order/all',
    detail : '/order'
  },
  documentType: {
    new    : '/documentType/new',
    list   : '/documentType/all',
    detail : '/documentType'
  },
  category: {
    list : '/category/all'
  },
  document: {
    list : '/document/all'
  },
  import: {
    pieces    : '/import/pieces',
    products  : '/import/products',
    gsisUsers : '/import/gsis-users'
  },
  additional: {
    errorFields : '/additional/errorField'
  },
};
