import { toast, TypeOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorException from '../../services/exception/ErrorException';
import WarningException from '../../services/exception/WarningException';

class Notification {

  public display(type: TypeOptions, text: string) {
    toast(text, {
      type      : type,
      className : 'border-radius-5',
    });
  }

  public displayException(err: ErrorException | WarningException | Error | any) {
    let type : TypeOptions;
    if (err instanceof ErrorException) {
      type = 'error';
    } else if (err instanceof WarningException) {
      type = 'warning';
    } else {
      type = 'error';
    }

    this.display(type, err.message);
  }

}

export default new Notification();
export { ToastContainer as NotificationContainer } from 'react-toastify';
